import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Intro from "../components/Intro";
import Seo from "../components/Seo";

const MediaTemplate = ({ intro, title  }) => {

  return (
    <div className="section">
      <div className="w-full max-w-screen-xl px-6 mx-auto">
        <div className={`text-center md:text-left pt-8 pb-12 md:py-16`}>
          <h1 className="lg:pr-12">{title}</h1>
        </div>
      </div>
      <Intro
        introImage={intro.introImage}
        title={intro.title}
        text={intro.text}
        link={intro.link}
        linkText={intro.linkText}
        introToggle={intro.introToggle ? intro.introToggle : false}
      />
    </div>
  );
};

MediaTemplate.propTypes = {
  title: PropTypes.string,
};

const MediaPage = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;

  return (
    <Layout>
         <Seo title={frontmatter.title} description={frontmatter.intro.text} />

      <MediaTemplate title={frontmatter.title} intro={frontmatter.intro} />
    </Layout>
  );
};

MediaPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const mediaPageQuery = graphql`
  query MediaPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        intro {
          title
          text
          link
          linkText
          introToggle
          introImage {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        title
      }
    }
  }
`;
export default MediaPage;